import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { Stack, Typography, Menu, MenuItem, styled, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SpotlightView from "../components/SpotlightView";

import './styles/Home.scss'


const StyledMenuItem = styled(MenuItem)`
  marginLeft: '8px',
  marginRight: '8px',
  borderRadius: '8px',
`

function Body({
    location,
    interest,
    setLocation,
    setInterest,
  }: {
    location: string,
    interest: string,
    setLocation: any,
    setInterest: any,
  }) {
    const [locationAnchorEl, setLocationAnchorEl] = useState<null | HTMLElement>(null);
    const locationOpen = Boolean(locationAnchorEl);
    const handleLocationClick = (event: React.MouseEvent<HTMLElement>) => {
      setLocationAnchorEl(event.currentTarget);
    };
    const handleLocationClose = () => {
      setLocationAnchorEl(null);
    };
  
    
    const [interestAnchorEl, setInterestAnchorEl] = useState<null | HTMLElement>(null);
    const interestOpen = Boolean(interestAnchorEl);
    const handleInterestClick = (event: React.MouseEvent<HTMLElement>) => {
      setInterestAnchorEl(event.currentTarget);
    };
    const handleInterestClose = () => {
      setInterestAnchorEl(null);
    };
  
    return (
      <Stack className="Home" spacing={1} px={3} mt="100px">
        <Stack className="location-selector" height="100%" width="100%" mt={8} alignItems="center" direction="row" justifyContent="center">
          <Typography className="location-info-text" style={{
            fontFamily: 'SatoshiBold',
          }}>
            Find date ideas in
          </Typography>
          <Stack direction="row" alignItems="center" px="18px" py={1} borderRadius="100px" onClick={handleLocationClick} sx={{
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#ECFFEE',
            }
          }}>
            <Typography className="location-text" style={{
              fontFamily: 'SatoshiBold',
              color: '#4c9b53',
              display: 'inline',
            }}>
              {location}
            </Typography>
            <KeyboardArrowDownIcon style={{
              height: '32px',
              width: '32px',
              color: '#4c9b53',
            }} />
          </Stack>
        </Stack>
  
        <Stack className="interest-selector" height="100%" width="100%" mt={8} alignItems="center" direction="row" justifyContent="center">
          <Typography className="interest-info-text" variant="h3" style={{
            fontFamily: 'SatoshiBold',
          }}>
            I'm interested in
          </Typography>
          <Stack direction="row" alignItems="center" px="18px" py={1} borderRadius="100px" onClick={handleInterestClick} sx={{
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#ECFFEE',
            }
          }}>
            <Typography className="interest-text" variant="h3" style={{
              fontFamily: 'SatoshiBold',
              color: '#4c9b53',
              display: 'inline',
            }}>
              {interest}
            </Typography>
              <KeyboardArrowDownIcon style={{
                height: '32px',
                width: '32px',
                color: '#4c9b53',
              }} />
          </Stack>
        </Stack>
  
          <Menu
            id="basic-menu"
            anchorEl={locationAnchorEl}
            open={locationOpen}
            onClose={handleLocationClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              borderRadius: '12px',
              boxShadow: '0px 4px 24px rgba(29, 28, 42, 0.06)',
            }}
          >
            <StyledMenuItem onClick={() => {
              handleLocationClose()
              setLocation('San Francisco, CA')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>
                San Francisco, CA
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => {
              setLocation('Toronto, ON')
              handleLocationClose()
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>Toronto, ON</Typography>
            </StyledMenuItem>
          </Menu>
          
          <Menu
            id="basic-menu"
            anchorEl={interestAnchorEl}
            open={interestOpen}
            onClose={handleInterestClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{
              borderRadius: '12px',
            }}
          >
            <StyledMenuItem onClick={() => {
              handleInterestClose()
              setInterest('anything')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>anything</Typography>
            </StyledMenuItem>
  
            <StyledMenuItem onClick={() => {
              handleInterestClose()
              setInterest('food-and-drink')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>food & drink</Typography>
            </StyledMenuItem>
  
            <StyledMenuItem onClick={() => {
              handleInterestClose()
              setInterest('entertainment')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>entertainment</Typography>
            </StyledMenuItem>
  
            <StyledMenuItem onClick={() => {
              handleInterestClose()
              setInterest('sports-and-fitness')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>sports & fitness</Typography>
            </StyledMenuItem>
  
            <StyledMenuItem onClick={() => {
              handleInterestClose()
              setInterest('travel-and-culture')
            }}>
              <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>travel & culture</Typography>
            </StyledMenuItem>
          </Menu>
      </Stack>
    )
  }

export default function Home() {
  const { search } = useLocation()

  const [location, setLocation] = useState('San Francisco, CA')
  const [interest, setInterest] = useState('anything')
    
  useEffect(() => {
    const values = queryString.parse(search)
    if (values.interest) {
      setInterest(values.interest as string)
    }
  }, [search])

  return (
    <Stack>
      <Body location={location} interest={interest} setLocation={setLocation} setInterest={setInterest} />
      <SpotlightView location={location} interest={interest} />
    </Stack>
  )
}