import { Stack, Typography } from "@mui/material";
import { SpotlightCard } from "../components/SpotlightView";
import { actualSpotlightItems } from "../constants";

import './styles/Partners.scss';

export default function Partners() {
    const bloomPartners = actualSpotlightItems.filter(item => item.isbloompartner)

    return (
        <Stack className="Partners" justifyContent="center" alignItems="center">
            <Stack maxWidth="1100px" width="100%" mt="100px">
                <Typography variant="h4" style={{
                    fontFamily: 'SatoshiBold',
                    marginBottom: '16px',
                }}>
                    Our partners
                </Typography>
                <Typography variant="body1" style={{
                    fontFamily: 'SatoshiMedium',
                    marginBottom: '106px',
                }}>
                    We’re proud to be partners with the following local businesses and brands. 
                </Typography>

                <Stack className="Partners-list" flexWrap={'wrap'} direction="row" columnGap="32px" rowGap="56px">
                    {bloomPartners.map((partner, index) => {
                        return <SpotlightCard key={index} spotlightItem={partner}  />
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}