import { Alert, Box, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';

import { CategoryBadge, SpotlightCard } from "../components/SpotlightView";
import type { SpotlightItemType } from "../components/SpotlightView";
import { actualSpotlightItems, primaryColor } from "../constants";

import './styles/Spotlight.scss';

export default function Spotlight() {
    const [isCopyLinkToastOpen, setIsCopyLinkToastOpen] = useState(false);
    
    const [spotlightItem, setSpotlightItem] = useState<SpotlightItemType>()

    const { id }: any = useParams();

    useEffect(() => {
        setSpotlightItem(actualSpotlightItems.find(item => item.url === `/spotlight/${id}`))
    }, [id])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setIsCopyLinkToastOpen(false);
    };

    const renderAdditionalSpotlights = () => {
        if (!spotlightItem) {
            return null
        }

        const additionalSpotlights = actualSpotlightItems.filter(item => item.location === spotlightItem?.location).filter(item => item.category === spotlightItem?.category).filter(item => item.homepage !== spotlightItem.homepage)

        if (additionalSpotlights.length === 0) {
            return null
        }

        return (
            <Stack className="Spotlight-additionalSpotlights" maxWidth="1100px" width="100%" mt={10}>
                <Typography style={{ fontFamily: 'SatoshiBold', marginBottom: '32px' }}>
                    You might also like
                </Typography>
                {
                    additionalSpotlights.slice(0, 3).map((item, index) => {
                        return <SpotlightCard key={index} spotlightItem={item} />
                    })
                }
            </Stack>
        )
    }
    

    if (!spotlightItem) {
        return <Typography style={{ fontFamily: 'SatoshiBold' }}>Sorry, we couldn't find that spotlight item!</Typography>
    }

    return (
        <Stack className="Spotlight" width="100%" alignItems="center">
            <Box className="Spotlight-cover-graphic" width="100%" height="600px" style={{
                backgroundImage: `url(${spotlightItem.coverPhotoUrl})`,
            }} />
                <Stack width="100%" maxWidth="1100px" alignItems="flex-start">
                    <Box className="Spotlight-avatar" style={{ backgroundImage: `url(${spotlightItem.coverPhotoUrl})` }} />
                </Stack>

                <Stack className="Spotlight-content" maxWidth="1100px" width="100%" direction="row" columnGap="60px" mt={6}>
                    <Stack flex={1}>
                        {spotlightItem.isbloompartner && <Stack className="Spotlight-bloomPartner" direction="row" spacing="4px" alignItems="center">
                            <Box className="PartnerIcon" />
                            <Typography style={{
                                fontFamily: 'SatoshiBold',
                                color: primaryColor,
                                fontSize: '16px',
                            }}>
                                Bloom partner
                            </Typography>
                        </Stack>}
                        
                        <Typography className="Spotlight-name" my={4} variant="h4" style={{ fontFamily: 'SatoshiBold' }}>
                            {spotlightItem.name}
                        </Typography>
                        <Stack spacing={4}>
                            <Typography variant="body1" style={{ fontFamily: 'SatoshiMedium' }}>{spotlightItem.info}</Typography>
                        </Stack>

                    </Stack>
                    <Stack className="Spotlight-sidebar" alignItems="center" maxWidth="355px" width="100%">
                        <Stack style={{
                            border: '1px solid rgba(18, 17, 32, 0.16)',
                            borderRadius: '26px',
                            padding: '14px 0',
                            width: '100%',
                            cursor: 'pointer',
                            marginBottom: '44px',
                        }} onClick={() => {
                            navigator.clipboard.writeText(spotlightItem.homepage)
                            setIsCopyLinkToastOpen(true)
                        }}>
                            <Typography variant="body1" style={{ fontFamily: 'SatoshiBold', textAlign: 'center' }}>
                                Share this page
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing="12px" alignItems="flex-start" width="100%">
                            <Box className="ArrowUpIcon" />
                            <a href={spotlightItem.homepage} style={{
                                fontFamily: 'SatoshiBold',
                                color: primaryColor,
                                textDecoration: 'none',
                            }}>{spotlightItem.homepage}</a>
                        </Stack>
                        
                        <Divider sx={{ width: '100%', my: 2 }} />

                        <Stack direction="row" spacing="12px" alignItems="center" width="100%">
                            <Box className="GlobeIcon" />
                            <Typography style={{ fontFamily: 'SatoshiBold', marginTop: '1px' }}>
                                {spotlightItem.address}
                            </Typography>
                        </Stack>

                        <Divider sx={{ width: '100%', my: 2 }} />

                        <Stack direction="row" spacing="12px" alignItems="center" width="100%">
                            <Box className="MoneyIcon" />
                            <Typography style={{ fontFamily: 'SatoshiBold' }}>
                                {spotlightItem.priceRange}
                            </Typography>
                        </Stack>

                        <Divider sx={{ width: '100%', my: 2 }} />

                        <Stack direction="row" spacing="12px" alignItems="center" width="100%">
                            <Box className="TagIcon" />
                            <CategoryBadge category={spotlightItem.category} />
                        </Stack>

                        <Divider sx={{ width: '100%', my: 2 }} />
                    </Stack>

                </Stack>
                {renderAdditionalSpotlights()}
                <Snackbar
                    open={isCopyLinkToastOpen}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Share link copied!
                    </Alert>
                </Snackbar>
        </Stack>
    )
}