
import { Box, Stack, Typography } from '@mui/material'

import { actualSpotlightItems } from '../constants'
import './styles/SpotlightView.css';

export type SpotlightItemType = {
  category: string,
  name: string,
  location: string,
  priceRange: string,  
  info: string,
  homepage: string,
  address: string,
  isbloompartner: boolean,
  coverPhotoUrl: string,
}

export function CategoryBadge({ category }: { category: string }) {
  return (
    <Box style={{
      backgroundColor: '#F4F1EE',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '8px',
      paddingRight: '8px',
      borderRadius: '33px',
      cursor: 'pointer',
    }}>
      <a href={`/?interest=${category}`} style={{
        textDecoration: 'none',
        color: 'black',
      }}>
        <Typography textAlign={'left'} style={{
          fontFamily: 'SatoshiBold',
        }}>
          {category}
        </Typography>
      </a>
    </Box>
  )
}

export function SpotlightCard({
    spotlightItem,
  }: any) {
    const {
      category,
      name,
      location,
      priceRange,
      url,
    } = spotlightItem;
  
    return (
      <Stack className="SpotlightCard" style={{ cursor: 'pointer' }}>
        <a href={url} style={{
          textDecoration: 'none',
          color: 'black',
        }}>
        <Box className="SpotlightCard-graphic" style={{
          borderRadius: '16px',
          backgroundImage: `url(${spotlightItem.coverPhotoUrl})`,
        }} />
        <Stack direction="row" justifyContent="space-between" mt="20px" alignItems="center">
          <Typography textAlign={'left'} style={{
            fontFamily: 'SatoshiBold',
          }}>
            {name}
          </Typography>
  
          <Box style={{
            backgroundColor: '#F4F1EE',
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '33px',
          }}>
            <Typography textAlign={'left'} style={{
              fontFamily: 'SatoshiBold',
            }}>
              {category}
            </Typography>
          </Box>
  
        </Stack>
        <Typography textAlign={'left'} style={{
            fontFamily: 'SatoshiMedium',
            color: 'rgba(0, 0, 0, 0.7)',
          }}>
            {location} • {priceRange}
        </Typography>
        </a>
      </Stack>
    )
  }
  
  function Spotlight({ spotlightItems }: { spotlightItems: SpotlightItemType[]}) {
    return (
      <Stack>
        <Stack className="SpotlightView-cards" direction="row" flexWrap="wrap" rowGap="56px" columnGap="32px" maxWidth="1144px">
          {spotlightItems.map((item, index) => {
            return <SpotlightCard key={index} spotlightItem={item} />
          })}
        </Stack>
      </Stack>
    )
  }
  
  export default function SpotlightView({
    location,
    interest,
  }: {
    location: string,
    interest: string
  }) {
    let spotlightItems = actualSpotlightItems.filter(item => item.location === location)
    if (interest !== 'anything') {
      spotlightItems = spotlightItems.filter(item => item.category === interest)
    }
  
    return (
      <Stack className="SpotlightView">
        <Typography variant="h5" style={{
          color: 'black',
          fontFamily: 'SatoshiBold',
          textAlign: 'left',
          maxWidth: '1144px',
        }}>
          Spotlight
        </Typography>
        <Stack direction="row" px={3} mt={2} spacing={12}>
          <Spotlight spotlightItems={spotlightItems} />
        </Stack>
      </Stack>
    )
  }