import { Box, IconButton, Stack, Typography } from '@mui/material'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { primaryColor } from './constants'
import Spotlight from './pages/Spotlight';
import Home from './pages/Home';
import Partners from './pages/Partners';

import './App.scss';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { getWindowSize } from './hooks/getWindowSize';
import { useEffect, useState } from 'react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  // console.log('what is the window size', windowSize)

  const getLinks = () => {
    if (windowSize.innerWidth < 600) {
      return (
        <IconButton onClick={() => setIsMenuOpen(true)} style={{ marginRight: '24px' }}>
          <Box className="MenuIcon" />
        </IconButton>
      )
    }
    return (
      <Stack className="Header-right" direction="row" spacing={5} alignItems="center">
        <a href="/partners" style={{
          textDecoration: 'none',
          color: 'black'
        }}>
          <Typography style={{
            fontFamily: 'SatoshiBold',
          }}>
            Partners
          </Typography>
        </a>
        <a href="https://www.lovebybloom.com" style={{
          textDecoration: 'none',
          color: 'black'
        }}>
          <Typography style={{
            fontFamily: 'SatoshiBold',
          }}>
            What is Bloom?
          </Typography>
        </a>
      </Stack>
    )
  }

  if (isMenuOpen) {
    return (
      <Stack
        alignItems="center"
        zIndex="1"
        position="absolute"
        left="0"
        right="0"
        paddingLeft="24px"
        paddingRight="24px"
        paddingBottom="40px"
        paddingTop="24px"
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.08)',
        }}>
        <Stack width="100%" alignItems="flex-end" mt={1}>
          <IconButton onClick={() => setIsMenuOpen(false)}>
              <Box className="CloseIcon" />
          </IconButton>
        </Stack>
        <a href="/partners" style={{
          textDecoration: 'none',
          color: 'black',
          marginBottom: '30px',
        }}>
          <Typography style={{
            fontFamily: 'SatoshiBold',
            fontSize: '18px',
          }}>
            Partners
          </Typography>
        </a>
        <a href="https://www.lovebybloom.com" style={{
          textDecoration: 'none',
          color: 'black',
          
        }}>
          <Typography style={{
            fontFamily: 'SatoshiBold',
            fontSize: '18px',
          }}>
            What is Bloom?
          </Typography>
        </a>
      </Stack>
    )
  }

  return (
    <Stack className="Header" direction="row" py={4} width="100%" justifyContent="center" position="absolute" top="0" left="0" right="0" style={{
      backgroundColor: 'white',
    }}>
      <Stack width="100%" maxWidth="1100px" justifyContent="space-between" direction="row" alignItems="center">
        <a href="/" style={{ textDecoration: 'none', color: 'black' }}>
          <Stack className="Header-left" alignItems="center" direction="row" style={{ cursor: 'pointer' }}>
            <Box py={'3px'} px={'9px'} style={{
              backgroundColor: primaryColor,
              borderRadius: 36,
            }}>
              <Typography style={{
                fontFamily: 'SatoshiBold',
                color: 'white',
              }}>
                dates
              </Typography>

            </Box>
            <Typography ml={1} style={{
              fontFamily: 'SatoshiBold',
            }}>
              by bloom
            </Typography>
          </Stack>
        </a>
        {getLinks()}
      </Stack>
    </Stack>
  )
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
            <Route path="/spotlight/:id" component={Spotlight} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/partners" component={Partners} />
            <Route path="/">
                <Home />
            </Route>
        </Switch>
      </div>
      <div className="footer">
        <div className="footer-container">
          <div className="primary-links">
              <p className="copyright-text" style={{
                  color: 'rgba(26, 26, 26, 0.8)',
                  fontSize: '18px',
              }}>
                  © 2022 The Bloom Company
              </p>
          </div>
          <div className="footer-links">
              <div className="social-links">
                  <p className="font-face-satoshi-bold" style={{ fontSize: '20px' }}>Social</p>
                  <a
                      className="link font-face-satoshi-regular" href="https://www.instagram.com/lovebybloom"
                      style={{
                        fontSize: '20px',
                        marginBottom: '11px',
                      }}
                  >
                      Instagram
                  </a>
                  <a
                      className="link font-face-satoshi-regular" href="https://lovebybloom.medium.com"
                      style={{
                        fontSize: '20px',
                        marginBottom: '11px',
                      }}
                  >
                      Medium
                  </a>
                  <a
                      className="link font-face-satoshi-regular" href="https://www.facebook.com/lovebybloom"
                      style={{
                        fontSize: '20px',
                        marginBottom: '11px',
                      }}
                  >
                      Facebook
                  </a>
              </div>
              <div className="legal-links">
                  <p className="font-face-satoshi-bold" style={{ fontSize: '20px' }}>Legal</p>
                  <a href="/privacy" className="link font-face-satoshi-regular" style={{
                    fontSize: '20px',
                    marginBottom: '11px',
                  }}>Privacy</a>
                  <a href="/terms" className="link font-face-satoshi-regular" style={{
                    fontSize: '20px',
                    marginBottom: '11px',
                  }}>Terms</a>
              </div>
              </div>
          </div>
        </div>
    </Router>
  );
}

export default App;
