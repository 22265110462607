// spotlight items

const actualSpotlightItems = [
    {
        "name": "The Library Specialty Coffee",
        "category": "food-and-drink",
        "location": "Toronto, ON",
        "priceRange": "$",
        "url": "/spotlight/thelibraryspecialtycoffee",
        "info": "Excellent coffee and expresso, great friendly service, and casual atmosphere.",
        "homepage": "https://www.thelibraryspecialtycoffee.com/",
        "address": "281 Dundas St W, Toronto, ON M5T 1G1, Canada",
        "isbloompartner": false,
        "coverPhotoUrl": "https://cdn.shopify.com/s/files/1/0061/4069/0496/files/fullsizeoutput_1093_960x640.jpeg?v=1613503855"
    },
    {
        "name": "Strange Love Coffee",
        "category": "food-and-drink",
        "location": "Toronto, ON",
        "priceRange": "$",
        "url": "/spotlight/strangelovecoffee",
        "info": "Chris and David opened up the first Strange Love cafe in 2016.\n\nThe duo has always believed in good energy, habits, and people, and it's what inspired their unique community spaces, and their award-winning coffee.\n\nWhen the pandemic came along, it forced them to think about the future of helping people develop good energy and habits. Like coffee, David and Chris have applied the same thoughtfulness to adaptogens and functional mushrooms.\n\nWe joined forces with Dr. James Yoon to be our in-house naturopathic doctor.\n\nOver the next year Strange Love has gone beyond helping people get energy from caffeine by crafting products that give you the ancient wisdom of mushrooms with modern science.\n\nEvery day our mushroom powered products help people just like you focus, relax, and energize.",
        "homepage": "https://strangelovecafe.com/",
        "address": "101 Spadina Ave., Toronto, ON M5V 2K2, Canada",
        "isbloompartner": false,
        "coverPhotoUrl": "https://cdn.shopify.com/s/files/1/0321/9229/9067/files/Sec12_3d4aaf56-40aa-41c7-bef3-e25df1b3dd05.png?v=1645532557"
    },
    {
        "name": "5 Drive-in",
        "category": "entertainment",
        "location": "Toronto, ON",
        "priceRange": "$",
        "url": "/spotlight/5drivein",
        "info": "The Allen family has been involved in the movie theatre business in canada for many decades. The Allens managed to build the finest and largest circuit in the world by 1920. Premier Operating corporation Limited and its subsidiaries and affiliates are the current remaining parts of the Allen organization. The companies in the group are Canadian-controlled private corporations and are owned, managed enterprises. Premier has been involved with the Drive-in theatre market since 1960, when it purchased the 5Drive-in in Oakville Ontario. Currently the company owns and manages theatres in Oakville, London, Hamilton, Newmarket. Additionally Premier own a theatre in Yellowknife.",
        "homepage": "http://5drivein.com/",
        "address": "2332 Ninth Line, Oakville, ON L6H 7G9, Canada",
        "isbloompartner": false,
        "coverPhotoUrl": "https://premiertheatres.ca/images/jump-in-your-car.jpg"
    },
    {
        "name": "Par-Tee Putt",
        "category": "entertainment'",
        "location": "Toronto, ON'",
        "priceRange": "$$",
        "url": "/spotlight/Par-teeputt",
        "info": "With 27 themed holes, we offer up nostalgic and quirky fun, along with delightful drinks and delicious eats. PAR-TEE PUTT is Toronto’s newest destination for a good time. Par none. So, dust off your visors, sharpen those tiny pencils and tee off with us!",
        "homepage": "https://parteeputt.com/toronto",
        "address": "26 Duncan St, Toronto, ON M5V 2B8, Canada",
        "isbloompartner": false,
        "coverPhotoUrl": "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.rollersgolf.com%2F&psig=AOvVaw2kF4wotz1rUirA-BBTJnbw&ust=1668146465269000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCOCWqf73ovsCFQAAAAAdAAAAABAE"
    },
    {
        "name": "Hayes Valley Bakeworks",
        "category": "food-and-drink'",
        "location": "San Francisco, CA'",
        "priceRange": "$",
        "url": "/spotlight/hayesvalleybakeworks",
        "info": "Hayes Valley Bakeworks operates the cafe as a non-profit “social enterprise.” In addition to our delicious food and coffee, we provide employment and training for people with disabilities, who are homeless or at risk.\n\nHayes Valley Bakeworks is the brainchild of Toolworks, a dynamic non-profit organization connecting ability to opportunity. Toolworks partners with the bakery to create a supportive environment for our participants who learn all facets of the food service business. Our local producers and suppliers also take part in our training program, offering career advice and sharing their passion for the food industry.",
        "homepage": "http://bakeworkssf.com/",
        "address": "550 Gough St, San Francisco, CA 94102",
        "isbloompartner": false,
        "coverPhotoUrl": "https://images.squarespace-cdn.com/content/v1/55bfc060e4b0ca6a13be5995/1600974277733-4F2NBELZW8Q6RECLIXBI/ginger+cookie.jpg?format=1500w"
    },
    {
        "name": "Grand Coffee",
        "category": "food-and-drink",
        "location": "San Francisco, CA",
        "priceRange": "$",
        "url": "/spotlight/grandcoffee",
        "info": "Equal parts a product of love and good timing, Grand Coffee was founded by Nabeel Silmi in the post-recession days of 2010. In the decade since, Grand has become a gathering place for all in the community: immigrants, artists, abuelas, tech workers, service workers, queer folx, people who just moved to San Francisco, people who have spent their whole lives in San Francisco, and so many more.\n",
        "homepage": "http://www.grandcoffeesf.com/",
        "address": "2663 Mission St, San Francisco, CA 94110",
        "isbloompartner": false,
        "coverPhotoUrl": "https://cdn.shopify.com/s/files/1/0102/1660/3745/files/subscribe.jpg?v=1648650479&width=535"
    },
    {
        "name": "Urban Putt",
        "category": "entertainment",
        "location": "San Francisco, CA",
        "priceRange": "$",
        "url": "/spotlight/urbanputt",
        "info": "Unique restaurant featuring American grub & drinks in an informal space housing a mini-golf course.\n",
        "homepage": "http://www.urbanputt.com/",
        "address": "1096 S Van Ness Ave, San Francisco, CA 94110",
        "isbloompartner": false,
        "coverPhotoUrl": "https://images.getbento.com/accounts/f4672aa403e03e81f18bd73eb35c503c/media/images/89722URBANPUTT_RUSH_August_2019_Brittni_Bell_Photo13.JPG?w=1800&fit=max&auto=compress,format&h=1800"
    },
    {
        "name": "Mar Vista Stables",
        "category": "entertainment",
        "location": "San Francisco, CA",
        "priceRange": "$",
        "url": "/spotlight/marvistastables",
        "info": "Horseback ride down to Thornton State Beach through rolling hills and wildflowers then ride along the beautiful Pacific Ocean watching for dolphins, seals, red tailed hawks and marine life. Located just minutes south of San Francisco off of Skyline Boulevard in Daly City, CA just south of Fort Funston.",
        "homepage": "http://marvistastable.com/",
        "address": "2152 Olympic Way, Daly City, CA 94015",
        "isbloompartner": false,
        "coverPhotoUrl": "https://marvistastable.com/wp-content/uploads/2014/01/Group-ride-beach-mar-vista-1-1024x768.jpg"
    }
]

// colors
const primaryColor = '#4B9B53'

export {
    primaryColor,
    actualSpotlightItems,
}